import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Table, Radio, Space } from "antd";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { getSalesSummary } from "../Dashboard/dashboardSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { HeaderCell } from "../Dashboard/HeaderCell";
import { IconDownload } from "../../components/IconDownload/IconDownload";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { dateCell } from "../../components/DateCell/DateCell";
import { CSVLink } from "react-csv";
import { Goods } from "../GoodsIn/GoodsIn";
import { CurrentOrders } from "../CurrentOrders/CurrentOrders";

export const SalesSummary = ({upc}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [type, setType] = useState("type");
  const [sortDirection, setSortDirection] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  const page = new URLSearchParams(location.search).get("page") || 1;
  const search = new URLSearchParams(location.search).get("search");
  const sort = new URLSearchParams(location.search).get("sort");

  const onQueryParamsChange = (values) => {
	const queryParams = {
	  upc,	
	  type,	
	  page,
	  search,
	  sort,
	  ...values,
	};
	//SetState?
	//const queryString = buildQueryString("/overview", queryParams);
	//navigate(queryString);
  };

  const { salesSummary, getSalesSummaryInProgress } =
	useSelector((state) => state?.dashboard) || {};

  const { data: rows, totalPages } = salesSummary || {};

  useEffect(() => {
	dispatch(
	  getSalesSummary({
		upc,
		type,  
		page,
		search,
		sort: sortDirection,
	  }),
	);
  }, [upc, type, page, search, sortDirection]);

  const columns = [
	{
	  title: "UPC",
	  editable: true,
	  dataIndex: "UPC",
	  width: 250,
	  sorter: (_, __, sort) =>
		setSortDirection(`UPC:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},
	{
	  title: "Account Name",
	  editable: true,
	  dataIndex: "Account_Name",
	  width: 250,
	  sorter: (_, __, sort) =>
		setSortDirection(`Account_Name:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	  hide: type == 'type'
	},
	{
	  title: "Account Type",
	  editable: true,
	  dataIndex: "Account_Type",
	  width: 250,
	  sorter: (_, __, sort) =>
		setSortDirection(`Account_Type:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	  hide: type == 'name'
	},
	{
	  title: "Transaction Date",
	  editable: true,
	  dataIndex: "Transaction_Date",
	  width: 250,
	  sorter: (_, __, sort) =>
		setSortDirection(`Transaction_Date:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	  render: (record) => dateCell(record)
	},
	{
	  title: "Sold Units",
	  editable: true,
	  dataIndex: "Sold_Units",
	  width: 100,
	  sorter: (_, __, sort) =>
		setSortDirection(`Sold_Units:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},
	{
	  title: "FOC Cons.",
	  editable: true,
	  dataIndex: "FOC_Consignment",
	  width: 100,
	  sorter: (_, __, sort) =>
		setSortDirection(`FOC_Consignment:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},
	{
	  title: "Returns",
	  editable: true,
	  dataIndex: "Returns",
	  width: 100,
	  sorter: (_, __, sort) =>
		setSortDirection(`Returns:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},	
  ].filter(column=>!column.hide);

	const salesTitle = <>Sales by Account <span className="capitalize">{type}</span></>;
  
	return (
		<Flex className={""} vertical>
			<SectionHeader title="Summary">
				<CSVLink data={rows || []} filename="sales_summary.csv">
					<IconDownload />
				</CSVLink>
			</SectionHeader>
			<Space direction="vertical"
				size={32}
				style={{
					display: 'flex',
				}}>
				<div>
					<Flex style={{
						marginBottom: "16px"
					}}>
						<Radio.Group value={type} onChange={(e)=>setType(e.target.value)}>
							<Radio.Button value="type">Account Type</Radio.Button>
							<Radio.Button value="name">Account Name</Radio.Button>
						</Radio.Group>
					</Flex>
					<SectionHeader title={salesTitle} />
					<Table
						components={{
							header: {
								row: HeaderCell,
							},
						}}
						columns={columns}
						rowKey={(record) => record.id}
						dataSource={rows}
						pagination={{
							position: ["bottomRight"],
							responsive: true,
							onChange: (page) => onQueryParamsChange({ page }),
							current: page,
							total: totalPages * 10,
							pageSize: 10,
							hideOnSinglePage: true,
							showSizeChanger: false,
						}}
						loading={getSalesSummaryInProgress}
						style={{ marginTop: 10 }}
						scroll={{ x: "100%" }}
					/>
				</div>
				<Goods upc={upc} />
				<CurrentOrders upc={upc} />
			</Space>
		</Flex>
  );
};
