import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Button, DatePicker, Select } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { getSearch } from "./searchSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { SearchSelect } from "../../components/SearchSelect/SearchSelect";

import css from "../Dashboard/Dashboard.module.css";
import formats from './Formats';
const { RangePicker } = DatePicker;

export const SearchBox = ({onApply}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [search, setSearch] = React.useState([]);
  const [searchValues, setSearchValues] = React.useState([]);
  const [format, setFormat] = React.useState([]);
  const [range, setRange] = React.useState({start: null, end: null});
  
  const reset = () => {
  	form.resetFields();
	setSearch([]);
	setSearchValues([]);
	setFormat([]);
	setRange({start: null, end: null})
  }
  
  const getFormattedSearch = async(query) => {
	  return dispatch(
		  getSearch({
			query,
		  })
	  ).then(values=>{
		return Array.isArray(values.payload) ? values.payload.map(result=>({
			label: `${result.name} (${result.entity})`,
			value: `${result.name} (${result.entity})`,
			term: result.name,
			entity: result.entity
		})) : [];  
	  })
  }
  
  const handleDateChange = (dates) => {
  	if(dates) {
  		const [start, end] = dates || [];
  		setRange({start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD')});
  	} 
	else {
		setRange({start: null, end: null});
	}
  };
  
  const initialDates = [range.start, range.end];
  const formatOptions = formats.map(format=>({
	  label: format,
	  value: format
  }))


  return (
	<Flex className={""} vertical>
	  <Form
		name="searchForm"
		style={{ marginTop: 10, marginBottom: 0 }}
		initialValues={{ remember: true }}
		autoComplete="off"
		form={form}
	  >
		<Row gutter={16} className={css.searchFormContainer}>
		  <Col xs={24} md={24} lg={12} xxl={10} className={css.formItem}>
				<label htmlFor="search">Search:</label>
				<Form.Item name="search">
					<SearchSelect
						mode="multiple"
						value={search}
						placeholder="Select labels, artists, product name, code and UPC"
						fetchOptions={getFormattedSearch}
						onChange={(newValue, selected) => {
							setSearch(newValue);
							setSearchValues(selected);
						}}
						style={{
							width: '100%',
						}}
						//className="select-search"
					/>
				</Form.Item>
		  </Col>
		  <Col xs={24} md={24} lg={12} xxl={6} className={css.formItem}>
				<label htmlFor="range">Release Date:</label>
		  	<Form.Item name="range">
		  		<RangePicker
			  		size={"large"}
			  		value={initialDates}
			  		onChange={handleDateChange}
		  		/>
		  	</Form.Item>
		  </Col>
		  <Col xs={24} md={24} lg={12} xxl={4} className={css.formItem}>
				<label htmlFor="format">Format:</label>
				<Form.Item name="format">
					<Select
						mode="multiple"
						allowClear
						options={formatOptions}
						onChange={setFormat}
						placeholder="Formats"
					/>
				</Form.Item>
		  </Col>
		  <Col xs={24} md={24} lg={12} xxl={4} className={css.actionsWrapper}>
				<Button type="default" htmlType="submit" onClick={()=>reset()}>
					Reset
				</Button>
				<Button type="primary" htmlType="submit" onClick={()=>onApply({searchValues, format, range})}>
					Search
				</Button>

		  </Col>
		</Row>
	  </Form>
	</Flex>
  );
};
