// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainLogo_logo__x8o67 {
    width: 150px;
    height: 26px;
    background-repeat: no-repeat;
    background-image: none;
}`, "",{"version":3,"sources":["webpack://./src/components/MainLogo/MainLogo.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,4BAA4B;IAC5B,sBAAsB;AAC1B","sourcesContent":[".logo {\n    width: 150px;\n    height: 26px;\n    background-repeat: no-repeat;\n    background-image: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `MainLogo_logo__x8o67`
};
export default ___CSS_LOADER_EXPORT___;
