import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Table } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";

import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { buildQueryString, debounce } from "../../utils/helpers";
import { IconDownload } from "../../components/IconDownload/IconDownload";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { SalesSummary } from "../SalesSummary/SalesSummary";
import { Goods } from "../GoodsIn/GoodsIn";
import { SearchBox } from "../SearchBox/SearchBox";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { CurrentOrders } from "../CurrentOrders/CurrentOrders";
import { SalesOrders } from "../SalesOrders/SalesOrders";
import { SalesTerritories } from "../SalesTerritories/SalesTerritories";
import { CSVLink } from "react-csv";

import css from "../Dashboard/Dashboard.module.css";

export const Details = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { upc } = useParams();
  return (<>
  	<Breadcrumbs paths={[{path: `/details/${upc}`, breadcrumbName: `Product Details - ${upc}`}]} />
	<PageHeader title={`Product Details - ${upc}`}>
	</PageHeader>
	<Flex className={css.root} vertical>
	  {upc && <div className={"ibox-holder"}><SalesTerritories upc={upc} /></div>}

	</Flex>
  </>
  );
};
