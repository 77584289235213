import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Table } from "antd";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { getGoods } from "../Dashboard/dashboardSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { HeaderCell } from "../Dashboard/HeaderCell";
import { IconDownload } from "../../components/IconDownload/IconDownload";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { dateCell } from "../../components/DateCell/DateCell";
import { CSVLink } from "react-csv";

export const Goods = ({upc}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [type, setType] = useState("type");
  const [sortDirection, setSortDirection] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  const page = new URLSearchParams(location.search).get("page") || 1;
  const search = new URLSearchParams(location.search).get("search");
  const sort = new URLSearchParams(location.search).get("sort");

  const onQueryParamsChange = (values) => {
	const queryParams = {
	  upc,	
	  type,	
	  page,
	  search,
	  sort,
	  ...values,
	};
	//SetState?
	//const queryString = buildQueryString("/overview", queryParams);
	//navigate(queryString);
  };

  const { goods, getGoodsInProgress } =
	useSelector((state) => state?.dashboard) || {};

  const { data: rows, totalPages } = goods || {};

  useEffect(() => {
	dispatch(
	  getGoods({
		upc,
		type,  
		page,
		search,
		sort: sortDirection,
	  }),
	);
  }, [upc, type, page, search, sortDirection]);

  const columns = [
	{
	  title: "UPC",
	  editable: true,
	  dataIndex: "UPC",
	  width: 250,
	  sorter: (_, __, sort) =>
		setSortDirection(`UPC:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},
	{
	  title: "Receipt Date",
	  editable: true,
	  dataIndex: "Receipt_Date",
	  width: 250,
	  sorter: (_, __, sort) =>
		setSortDirection(`Receipt_Date:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	  render: (record) => dateCell(record)
	},
	{
	  title: "Reference",
	  editable: true,
	  dataIndex: "Reference",
	  width: 250,
	  sorter: (_, __, sort) =>
		setSortDirection(`Reference:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},
	{
	  title: "Order Number",
	  editable: true,
	  dataIndex: "Order_Number",
	  width: 100,
	  sorter: (_, __, sort) =>
		setSortDirection(`Order_Number:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	},
	{
	  title: "Total Qty",
	  editable: true,
	  dataIndex: "Total_Qty",
	  width: 100,
	  sorter: (_, __, sort) =>
		setSortDirection(`Total_Qty:${sort === "ascend" ? "asc" : "desc"}`),
	  align: "left",
	}
  ];

  return (
		<Flex className={""} vertical>
			<SectionHeader title="Goods In">
				<CSVLink data={rows || []} filename="goods_in.csv">
					<IconDownload />
				</CSVLink>
			</SectionHeader>
			<Table
				components={{
					header: {
						row: HeaderCell,
					},
				}}
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={rows}
				pagination={{
					position: ["bottomRight"],
					responsive: true,
					onChange: (page) => onQueryParamsChange({ page }),
					current: page,
					total: totalPages * 10,
					pageSize: 10,
					hideOnSinglePage: true,
					showSizeChanger: false,
				}}
				loading={getGoodsInProgress}
				style={{ marginTop: 10 }}
				scroll={{ x: "100%" }}
			/>
		</Flex>
  );
};
