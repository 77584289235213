import axios from "axios";
import { buildQueryString, authHeader } from "../utils/helpers";
const { REACT_APP_SERVER_URL, REACT_APP_ORTHUS_URL, REACT_APP_API_BASIC_AUTH_USERNAME, REACT_APP_API_BASIC_AUTH_PASSWORD } = process.env;
const { REACT_APP_RIOT_URL, REACT_APP_RIOT_API_USER, REACT_APP_RIOT_API_TOKEN, REACT_APP_MINI_CATALOG_URL } = process.env;

//axios.defaults.auth = {username: REACT_APP_API_BASIC_AUTH_USERNAME, password: REACT_APP_API_BASIC_AUTH_PASSWORD};
//axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

export const loginRequestOrthus = (email, password) => {
  return axios.post(`${REACT_APP_ORTHUS_URL}/v1/users/sign_in`,{ email, password });
}

export const loginRequest = (email, password) => {
  return axios.post(`${REACT_APP_RIOT_URL}/v1/people/sign_in`,{ email, password }, { headers: authHeader() } );
}

export const logoutRequest = () => {
  return axios.delete(`${REACT_APP_RIOT_URL}/v1/people/sign_out`, { headers: authHeader() } );
}

export const getImprintsRequest = (ids) => {
  if(Array.isArray(ids))
    ids = ids.join(',');
  return axios.get(`${REACT_APP_MINI_CATALOG_URL}/v2/imprints/ids_by_riot/${ids}`);
}

export const getOverviewRequestGET = ({ page, search, sort, type }) => {
  console.log(search);
  const { search: searchTerm, format, range: {start, end}} = search;
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/overview?search=${searchTerm}&format=${format}&range_start=${start?start:''}&range_end=${end?end:''}&page=${page}&sort=${sort}`,
    { headers: authHeader() }
  );
};

export const getOverviewRequest = ({ page, search, sort, type }) => {
  console.log(search);
  const { search: searchTerm, format, range: {start, end}} = search;
  return axios.post(
    `${REACT_APP_SERVER_URL}/api/overview?&page=${page}&sort=${sort}`, {search}, 
    { headers: authHeader() }
  );
};


export const getSalesSummaryRequest = ({ upc, type, page, filters, sort }) => {
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/sales/${upc}/${type}?filter=${filters}&page=${page}&sort=${sort}`,
    { headers: authHeader() }
  );
};

export const getGoodsRequest = ({ upc, page, filters, sort }) => {
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/goods/${upc}?filter=${filters}&page=${page}&sort=${sort}`,
    { headers: authHeader() }
  );
};

export const getCurrentOrdersRequest = ({ upc, page, filters, sort }) => {
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/orders/${upc}?filter=${filters}&page=${page}&sort=${sort}`,
    { headers: authHeader() }
  );
};

export const getSalesOrdersRequest = ({ upc, start, end, mode, page, filters, sort }) => {
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/sales_orders/${upc}/${start}/${end}?mode=${mode}&filter=${filters}&page=${page}&sort=${sort}`,
    { headers: authHeader() }
  );
};

export const getSearchRequest = ({ query }) => {
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/search/${query}`,
    { headers: authHeader() }
  );
};

export const getSalesTerritoriesRequest = ({ upc, page, filters, sort }) => {
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/sales_territories/${upc}?filter=${filters}&page=${page}&sort=${sort}`,
    { headers: authHeader() }
  );
};
