import React from "react";
import { Col, Flex, Layout, Row } from "antd";
import css from "./Footer.module.css";
import { QuestionCircleFilled } from '@ant-design/icons';
const { Footer: AntFooter } = Layout;

export const Footer = () => {
  return (
    <AntFooter className={css.footer}>
      <Flex vertical={false} align="center" justify="space-between" className={css.footerInner}>
        <Row className={css.rowLeft}>
          <Col>
            <a href="#" className={css.help}>Help Articles <QuestionCircleFilled /></a>
          </Col>
          <Col className={css.copy}>Copyright ©️ 2024</Col>
        </Row>
        <Row gutter={16} className={css.rowRight}>
          <Col className={css.col}>TERMS</Col>
          <Col className={css.col}>PRIVACY</Col>
          <Col className={css.col}>DO NOT SELL MY PERSONAL INFORMATION</Col>
        </Row>
      </Flex>
    </AntFooter>
  );
};
