import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Table } from "antd";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { getSalesTerritories } from "../Dashboard/dashboardSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { HeaderCell } from "../Dashboard/HeaderCell";
import { IconDownload } from "../../components/IconDownload/IconDownload";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { dateCell } from "../../components/DateCell/DateCell";
import { CSVLink } from "react-csv";

export const SalesTerritories = ({upc}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [sortDirection, setSortDirection] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  const page = new URLSearchParams(location.search).get("page") || 1;
  const search = new URLSearchParams(location.search).get("search");
  const sort = new URLSearchParams(location.search).get("sort");

  const onQueryParamsChange = (values) => {
	const queryParams = {
	  upc,		
	  page,
	  search,
	  sort,
	  ...values,
	};
	//SetState?
	//const queryString = buildQueryString("/overview", queryParams);
	//navigate(queryString);
  };

  const { salesTerritories, getSalesTerritoriesInProgress } =
	useSelector((state) => state?.dashboard) || {};

  const { data: rows, totalPages } = salesTerritories || {};

  useEffect(() => {
	dispatch(
	  getSalesTerritories({
		upc,  
		page,
		search,
		sort: sortDirection,
	  }),
	);
  }, [upc, page, search, sortDirection]);

  const columns = [

  {
	title: "Territory",
	editable: true,
	dataIndex: "Territory",
	sorter: (_, __, sort) =>
	  setSortDirection(`Territory:${sort === "ascend" ? "asc" : "desc"}`),
	width: 200,
	align: "left",
  },
  {
	title: "SD Ship",
	editable: true,
	dataIndex: "sd_ship",
	sorter: (_, __, sort) =>
	  setSortDirection(`sd_ship:${sort === "ascend" ? "asc" : "desc"}`),
	width: 150,
	align: "left",
  },
  {
	title: "Cons. Stock",
	editable: true,
	dataIndex: "cons_stock",
	sorter: (_, __, sort) =>
	  setSortDirection(`cons_stock:${sort === "ascend" ? "asc" : "desc"}`),
	width: 150,
	align: "left",
  },
  {
	title: "Cons. 7 Day Sales",
	editable: true,
	dataIndex: "cons_seven_day_sales",
	sorter: (_, __, sort) =>
	  setSortDirection(`cons_seven_day_sales:${sort === "ascend" ? "asc" : "desc"}`),
	width: 150,
	align: "left",
  },
  {
	title: "Cons. Total Sales",
	editable: true,
	dataIndex: "cons_total_sales",
	sorter: (_, __, sort) =>
	  setSortDirection(`cons_total_sales:${sort === "ascend" ? "asc" : "desc"}`),
	width: 150,
	align: "left",
  }
];

  return (
		<Flex className={""} vertical>
			<SectionHeader title="Territories">
				<CSVLink data={rows || []} filename="sales_territories.csv">
					<IconDownload />
				</CSVLink>
			</SectionHeader>
			<Table
				components={{
					header: {
						row: HeaderCell,
					},
				}}
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={rows}
				pagination={{
					position: ["bottomRight"],
					responsive: true,
					onChange: (page) => onQueryParamsChange({ page }),
					current: page,
					total: totalPages * 10,
					pageSize: 10,
					hideOnSinglePage: true,
					showSizeChanger: false,
				}}
				loading={getSalesTerritoriesInProgress}
				style={{ marginTop: 10 }}
				scroll={{ x: "100%" }}
			/>
		</Flex>
  );
};
